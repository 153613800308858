/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: var(--color-primary);

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow:
        0 0 10px var(--color-primary),
        0 0 5px var(--color-primary);
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
}
