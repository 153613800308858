@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .react-grid-item.react-grid-placeholder {
      @apply !bg-surface-interact border-2 border-dashed border-surface-accent transition-all duration-200 ease-in-out;
    }
    .react-grid-item > .react-resizable-handle::after {
        @apply !border-r-content-muted !border-b-content-muted;
    }
    .react-grid-item.react-draggable {
        @apply cursor-grab;
    }
    .react-grid-item.react-draggable.react-draggable-dragging {
        @apply cursor-grabbing;
    }
}


